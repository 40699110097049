import { React } from 'react'
import { Grid } from '@mui/material'
import ContactForm from '../Components/ContactForm';


import {
  LeftContent,
  LeftContentBox,
  MyIconWrapper,
  MyLocationOnIcon,
  LeftContentText,
  MyPhoneIcon,
  MySendIcon,
  MyPublicIcon,
  MyContainer,
  RightContent
} from '../Assets/Css/ContactStyles';


function Contact() {
  
return (
    <>
    <MyContainer id="contact" maxWidth="md">
      <Grid container spacing={2}>
          <LeftContent item lg={5}>
            <LeftContentBox>
              <Grid item lg={2}>
                <MyIconWrapper>
                  <MyLocationOnIcon />
                </MyIconWrapper>
              </Grid>
              <Grid item lg={10}>
                <LeftContentText>Adresse: 288 Rue de la Carnoy, 59130 Lambersart</LeftContentText>
              </Grid>
            </LeftContentBox>
           
            <LeftContentBox>
              <Grid item lg={2}>
                <MyIconWrapper>
                  <MyPhoneIcon />
                </MyIconWrapper>
              </Grid>
              <Grid item lg={10}>
                <LeftContentText>Telephone: 03 20 59 07 25</LeftContentText>
              </Grid>
            </LeftContentBox>
            
            <LeftContentBox>
              <Grid item lg={2}>
                <MyIconWrapper>
                <MySendIcon />
                </MyIconWrapper>
              </Grid>
              <Grid item lg={10}>
                <LeftContentText>Email: contact@multitraitements.fr</LeftContentText>
              </Grid>
            </LeftContentBox>

            <LeftContentBox last="true">
              <Grid item lg={2}>
                <MyIconWrapper>
                  <MyPublicIcon />
                </MyIconWrapper>
              </Grid>
              <Grid item lg={10}>
                <LeftContentText>Website http://jlm-traitements.fr/</LeftContentText>
              </Grid>
            </LeftContentBox>
          </LeftContent>

          <RightContent item lg={7}>
            <h1>Contactez-nous</h1>
            <ContactForm />
          </RightContent>
      </Grid>
    </MyContainer>
    </>
  )
}

export default Contact