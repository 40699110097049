import { AppBar, Box, Container, Toolbar, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import DehazeIcon from '@mui/icons-material/Dehaze';
import { Link } from "react-scroll";


const MyTypography = styled(Typography)(({ theme }) => ({
    display: "inline-block"
}));

const MyAppbar = styled(AppBar)(({ theme }) => ({
    display: "flex"
}));

const MyLink = styled(`a`)(({ theme }) => ({
    color: "black",
    textDecoration: "none",
    padding: "10px 20px",
    cursor: "pointer",
    fontFamily: 'Proxima Nova Bold',
    [theme.breakpoints.down('sm')]: {
        paddingLeft: "12px",
        paddingBottom: "12px",
        paddingRight: "50px",
        paddingTop: "12px;",
        fontSize: "18px"
    },
    [theme.breakpoints.down('lg')]: {
        fontSize: "15px"
    }
}));

const MyPhoneNumber = styled(`a`)(({ theme }) => ({
    color: "white",
    textDecoration: "none",
    padding: "10px 30px",
    borderRadius: "10px",
    marginLeft: "16px",
    backgroundColor: "rgb(121, 200, 100)",
    cursor: "pointer",
    fontFamily: 'Proxima Nova Bold',
    [theme.breakpoints.down('sm')]: {
        paddingLeft: "12px",
        paddingBottom: "12px",
        paddingRight: "50px",
        paddingTop: "12px;",
        fontSize: "18px"
    }
}));

const MyScrollLink = styled(Link)(({ theme }) => ({
    color: "black",
    textDecoration: "none",
    padding: "10px 20px",
    cursor: "pointer",
    fontFamily: 'Proxima Nova Bold',
    [theme.breakpoints.down('sm')]: {
        paddingLeft: "12px",
        paddingBottom: "12px",
        paddingRight: "50px",
        paddingTop: "12px;",
        fontSize: "18px"
    }
}));

const BrandLink = styled(`a`)(({ theme }) => ({
    color: "black",
    textDecoration: "none",

    "& .brandImage" : {
        width: "100%",
        [theme.breakpoints.down('lg')]: {
            width: "250px",
            paddingLeft: "20px"
        },
        [theme.breakpoints.down('md')]: {
            width: "250px",
            paddingLeft: "20px"
        }
    }
}));

const SideBarIcon = styled(DehazeIcon)(({ theme }) => ({
    display: "none",
    paddingRight: "12px",
    color: 'black',
    cursor: 'pointer',
    [theme.breakpoints.down("md")]: {
        display: "inline-block"
    }
}));

const MyBox = styled(Box)(({ theme }) => ({
    [theme.breakpoints.down('md')]: {
        display: "none"
    }
}));

const MyToolbar = styled(Toolbar)(({ theme }) => ({
    background: "white",
    paddingTop: "12px",
    paddingBottom: "12px",
}));

const MyContainer = styled(Container)(({ theme }) => ({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
}));


export {
    MyTypography,
    MyAppbar,
    MyLink,
    MyToolbar,
    MyContainer,
    MyBox,
    BrandLink,
    SideBarIcon,
    MyScrollLink,
    MyPhoneNumber
}