import { ImageList, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/system';

const MyImageTitle = styled(`a`)(({ theme }) => ({
    textDecoration: "none",
    color: "white"
}));

const MyBox = styled(Box)(({ theme }) => ({
    paddingBottom: "7em"
}));

const MyTitle = styled(Typography)(({ theme }) => ({
    textAlign: "center",
    marginBottom: "32px",
    fontSize: "28px",
    textTransform: "uppercase"
}));

const MyImageList = styled(ImageList)(({ theme }) => ({
    gridTemplateColumns: "repeat(auto-fill, minmax(280px, 1fr)) !important"
}));

const MyIconBtn = styled(`a`)(({ theme }) => ({
    color: "white"
}));

export {
    MyImageTitle,
    MyBox,
    MyTitle,
    MyIconBtn,
    MyImageList
}