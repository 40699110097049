import { Box, Container, Typography, Grid } from '@mui/material'
import React from 'react'
import aboutUsImage from "../Assets/Images/aboutUsImage.webp"
import { Section } from '../Assets/Css/AboutStyles'

function About() {
  return (
    <>
        <Section>
            <Container maxWidth="lg">
                <Grid container spacing={1}>
                    <Grid item lg={7} md={7} sm={12} className='content-section'>
                        <Box>
                            <Typography className='title' variant='h4'><strong>À Propos De Nous</strong></Typography>
                        </Box>
                        <Box className='content'>
                            <Typography variant='h5'>JLM Multi-traitements Spécialiste expérimenté</Typography>
                            <p>
                                JLM Multi-traitements est une société de traitement des nuisibles :
                            </p>
                            <ul>
                                <li>Dératisation: rats, souris</li>
                                <li>Désinsectisation: guêpes/frelons, punaises de lits, puces, cafards, fourmis, acariens</li>
                                <li>Traitement des taupes</li>
                                <li>Solutions contre pigeons et volatiles</li>
                                <li>Plan prévention des métiers de bouche : boulangeries / restauration / traiteurs / industries alimentaires</li>
                            </ul>
                            <p>
                                Nous nous déplaçons sur toutes la région Hauts De France et vous assurons un service discret, de qualité et respectueuse de votre environnement
                            </p>
                        </Box>
                    </Grid>

                    <Grid item lg={5} md={5} sm={12} className='image-section'>
                        <img src={aboutUsImage} alt="dératiseur de JLM-Multitraitements" />
                    </Grid>
                </Grid>
            </Container>
        </Section>
    </>
  )
}

export default About