import { Box, Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';


const MyWrapper = styled(Box)(({ theme }) => ({
    width: "100%",
    height: "auto",
    backgroundColor: "#2F2F2F",
    paddingTop: "1rem",
    paddingBottom: "1rem",
}));

const MyPhoneWrapper = styled(Grid)(({ theme }) => ({
    display: "flex",
    justifyContent: "center",
    paddingTop: "1em",
    paddingBottom: "1em",
    borderBottom: "2px solid DarkGray",
    borderTop: "2px solid DarkGray",
    marginBottom: "2em",
    marginTop: "2em",
}));

const MyMenu = styled(`div`)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    marginBottom: "2em",
    flexWrap: "wrap",
    alignContent: "center",
    [theme.breakpoints.down('sm')]: {
        alignContent: "normal"
    }
}));

const MyMenuTitle = styled(Typography)(({ theme }) => ({
    color: "white",
    textDecoration: "none",
    fontSize: "21px"
}));

const MyMenuItem = styled(`a`)(({ theme }) => ({
    color: "white",
    textDecoration: "none",
    fontSize: "14px"
}));

const MyTypography = styled(Typography)(({ teme }) => ({
    color: "white",
    fontSize: "14px"
}))


export {
    MyWrapper,
    MyPhoneWrapper,
    MyMenu,
    MyMenuItem,
    MyMenuTitle,
    MyTypography
}