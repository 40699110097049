import Home from "./Pages/Home";
import Deratisation from "./Pages/Deratisation";
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import "./Assets/Css/main.css"
import DestructionGuepe from "./Pages/DestructionGuepe";
import Desinsectisation from "./Pages/Desinsectisation";
import SolutionVolatile from "./Pages/SolutionVolatile";
import TraitementTaupe from "./Pages/TraitementTaupe";
import TraitementAcariens from "./Pages/TraitementAcariens";
import PageNotFound from "./Pages/PageNotFound";
import Article from "./Pages/Article";
import articlesData from "./data/articlesData.json"
import Blog from "./Pages/Blog";

function App() {
  console.log("%c Site Web fait par Yannis Alouache \n https://github.com/Yannis-Alouache", 'background: black; color: #bada45; font-size:17px;');


  return (
    <>
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/deratisation-des-rats-et-souris/:slug?" element={<Deratisation />} />
        <Route path="/destruction-nids-de-guepes-et-frelons/:slug?" element={<DestructionGuepe />} />
        <Route path="/désinsectisation/:slug?" element={<Desinsectisation />} />
        <Route path="/depigeonnage/:slug?" element={<SolutionVolatile />} />
        <Route path="/traitement-des-taupes/:slug?" element={<TraitementTaupe />} />
        <Route path="/traitement-des-acariens/:slug?" element={<TraitementAcariens />} />
        <Route path="/actualité/" element={<Blog />} />
        {articlesData.map((article) => {
          return (
            <Route path={article.slug} key={article.title} element={<Article {...article} />} />
          )
        })}
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </Router>
    </>
  );
}

export default App;
