import { React, useState, useEffect } from 'react'
import test from "../Assets/Images/test.jpg"

import { Grid } from '@mui/material'

import {
    MyTitle,
    MySubTitle,
    MyText,
    MyGrid,
    MyWrapper,
    MyLink,
    MyImage,
    MyContainer,
} from '../Assets/Css/HeroBannerStyles'

function HeroBanner() {
    const [width, setWidth] = useState(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const isMobile = width <= 768;

    return (
        <>
            <MyWrapper>
                <picture>
                    <source media='(max-width: 768px)' srcSet={test} />
                    <source media='(min-width: 768px)' srcSet={test} />
                        <MyImage 
                            alt="Un dératiseur de JLM en intervention dans le nord"
                            src={test}
                        />
                </picture>


                <MyGrid container spacing={2}>
                    <MyContainer maxWidth="lg">
                        <Grid item lg={6}>
                            <MyTitle>JLM Multi-Traitements</MyTitle>
                            <MySubTitle>Elimine les nuisibles</MySubTitle>
                            <MyText>JLM Multi-Traitements est une société de traitement des nuisibles : rats, volatiles, pigeons, taupes, guêpes, souris ... elle se déplace sur toute la région Nord et vous assure un service discret, de qualité et respectueuse de votre environnement </MyText>

                            {isMobile ? (
                                <MyLink className="phone" to='nav' activeClass="active">
                                    03 20 59 07 25
                                </MyLink>
                            ) : (
                                <MyLink
                                activeClass="active"
                                to="nosServices"
                                spy={true}
                                smooth={true}
                                offset={-100}   
                                duration={500}
                                loading="lazy"    
                                className='mr'                        
                                >
                                    NOS SERVICES
                                </MyLink> 
                            )}
                        </Grid>
                    </MyContainer>
                </MyGrid>
            </MyWrapper>
        </>
    )
}

export default HeroBanner