import React from 'react'
import { MyBox, MyButton, MyLink, MyTitle } from '../Assets/Css/FeedBackBannerStyles'
import { Container } from '@mui/system'

function FeedBackBanner() {



  return (
    <MyBox id="avis">
        <Container>
            <MyTitle variant="h5">JLM-Traitements est la seule société de dératisation et de traitement des nuisibles noté 5/5 sur Google Avis</MyTitle>
            <MyButton variant="outlined">
                <MyLink target="_blank" rel="noreferrer" href="https://www.google.com/search?q=jlm+traitement&sxsrf=AJOqlzX1vj6DAY-N0FJFFJIsV3E3IdPTbA%3A1673358366905&ei=Hmy9Y7HfNpiskdUP9cmcuAc&gs_ssp=eJzj4tVP1zc0TDJLKcgrMi8wYLRSNagwMU82NkpMtLBMTjM2SExNsjKoMDJOtDRJSjM0TTEwMTE2s_Tiy8rJVSgpSswsSc1NzSsBAMaSFP0&oq=jlm+tra&gs_lcp=Cgxnd3Mtd2l6LXNlcnAQAxgBMgQIIxAnMg4ILhCABBDHARCvARDLATIICAAQgAQQywEyCAgAEIAEEMsBMggIABCABBDLATIOCC4QgAQQxwEQrwEQywEyCggAEIAEEIcCEBQyCggAEIAEEIcCEBQyBQgAEIAEMgUIABCABDoHCCMQsAMQJzoKCAAQRxDWBBCwAzoLCC4QgAQQxwEQrwE6BAgAEEM6DgguEK8BEMcBEIAEEMsBSgQIQRgASgQIRhgAUPQTWOQeYPskaAJwAXgAgAGNAYgBoQSSAQMyLjOYAQCgAQHIAQrAAQE&sclient=gws-wiz-serp#lrd=0x47c32aa89cf30aeb:0x23a94bf15d044369,1,,,,">
                    Lire les avis de nos clients
                </MyLink>
            </MyButton>  
        </Container>
    </MyBox>
  )
}

export default FeedBackBanner