import { styled } from '@mui/material/styles';
import { Container } from "@mui/system";
import Grid from '@mui/material/Grid';
import lille from "../Images/lilleDarken.webp"
import { Typography } from '@mui/material';


const MyBanner = styled(Grid)(({ theme }) => ({
    background: "center url(" + lille + ")",
    padding: "10em",
    
    [theme.breakpoints.down('sm')]: {
        padding: "20px",
        paddingRight: "10px",
        paddingLeft: "10px",
        background: "#7bd095",
    }

}));

const MyTitle = styled(Typography)(({ theme }) => ({
    [theme.breakpoints.down('sm')]: {
        fontSize: "24px",
    },
    color: "white",
    paddingBottom: "5px",
    textTransform: "uppercase",
}));

const MySubTitle = styled(Typography)(({ theme }) => ({
    [theme.breakpoints.down('sm')]: {
        fontSize: "17px",
    },
    color: "white",
    paddingBottom: "15px"
}));

const MyText = styled(Typography)(({ theme }) => ({
    [theme.breakpoints.down('sm')]: {
        fontSize: "16px",
    },
    color: "white",
}));


const MyWrapper = styled(Container)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
}));


export {MyBanner, MyWrapper, MyTitle, MySubTitle, MyText}