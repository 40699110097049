import React from 'react'
import Navbar from '../Layouts/Navbar';
import Banner from '../Layouts/Banner';
import { Container } from '@mui/system';
import styles from '../Assets/Css/ArticleStyles';
import Footer from '../Layouts/Footer';
import { capitalize } from '../Services/capitalize';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';


function Desinsectisation() {

  let { slug } = useParams();
  
  if (slug)
    slug = capitalize(slug)

  const bannerTitle = slug ? `Désinsectisation à ${slug}` : 'Désinsectisation'
  const title = slug ? `Désinsectisation à ${slug} | JLM Multitraitement` : "Désinsectisation | JLM Multitraitement"

  useEffect(() => {
    document.title = title;
  }, [title])

  return (
    <>
        <Navbar />
        <Banner title={bannerTitle}/>

        <div style={styles.spacer}></div>

        <Container maxWidth="lg">
            <p style={styles.spacing}>L’invasion des maisons et des appartements par des insectes tels que les blattes, les cafards, les puces et les punaises représentent un véritable cauchemar. Ils se baladent librement dans votre cuisine, votre chambre et peuvent devenir une obsession à laquelle aucune solution ne semble fonctionner. Si vous vous retrouvez dans cette situation, mettez les bombes insecticides et autres aérosols de côté : pour en finir une bonne fois pour toutes avec ces visiteurs non désirés, prenez les choses en main et faîtes appel à des professionnels.</p>
            
            <h2 style={styles.spacing}>1) Comment ça marche?</h2>
            <p style={styles.spacing}>Afin d’exterminer ces insectes et de s’en débarrasser définitivement, notre équipe est formée à leur comportement et à leurs habitudes (reproduction, déplacements, etc) afin d’assainir votre maison et éviter qu’ils se répandent dans les locaux voisins.</p>
            <p style={styles.spacing}>Si vous remarquez des cafards qui se promènent sur vos murs, des punaises ou des termites dans votre literie, une prolifération de moustiques dans de l’eau stagnante, faîtes appel à nous avant que cela devienne de plus en plus grâce car dans ce genre de situation, vous pouvez être sûr(e) d’une chose : ils ne partiront pas d’un coup de baguette machine (et ni à l’aide d’un simple insecticide).</p>
            <p style={styles.spacing}>Le principe est simple : vous nous contactez, nous vous envoyons un technicien sur place et il procédera au constat des travaux à fournir. Il pourra alors établir un devis, vous le soumettre et commencer à opérer dès qu’il sera signé.</p>
        
            <h2 style={styles.spacing}>2) Pourquoi faire appel à une entreprise spécialisée ?</h2>
            <p style={styles.spacing}>Pour une seule et bonne raison : les professionnels disposent de techniques et de produits conçus pour exterminer ces nuisibles. Ils sont plus puissants et plus nocifs que ceux que l’on trouve dans le commerce et ne sont pas destinés à un usage domestique.</p>
            <p style={styles.spacing}>De façon générale, les résultats avoisinent les 80% après le premier passage pour atteindre les 100% après 24 à 48 heures.</p>
            <p style={styles.spacing}>Ils doivent être manipulés par des professionnels avec la plus grande précaution et avec un vêtement de protection adéquate.</p>
            <p style={styles.spacing}>Ensuite, le professionnel connait le nuisible et peut donc anticiper son évolution au sein de vos locaux : il sait où un nid peut se nicher, quels sont leurs endroits favoris, et peuvent vous donner des conseils avérés pour éviter tout récidive.</p>
            
            <h2 style={styles.spacing}>3) Les plus de JLM : les clients à l’honneur</h2>
            <p style={styles.spacing}>Notre entreprise met un point d’honneur à la satisfaction du client et à la perfection des résultats. Nous vous engageons donc de vous offrir le deuxième ou le troisième passage si nos techniciens ne sont pas parvenus à exterminer la totalité des nuisibles lors du premier passage.</p>
            <p style={styles.spacing}>N’hésitez pas à faire appel à nous pour toute demande de devis gratuit ou de diagnostique : nous mettons tout en oeuvre pour vous aider à retrouver rapidement la salubrité de vos locaux professionnels ou privés.</p>

            <div style={styles.spacer}></div>
        </Container>

        <Footer />
    </>
  )
}

export default Desinsectisation