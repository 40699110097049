import { Container, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';


const MyBanner = styled(Grid)(({ theme }) => ({
    background: "rgb(0,83,75) linear-gradient(315deg, rgba(0,83,75,1) 0%, rgba(9,121,110,1) 10%, rgba(123,208,149,1) 100%)",
    padding: "10em",

    [theme.breakpoints.down('sm')]: {
        padding: "35px",
        height: "200px",
    }

}));

const MyWrapper = styled(Container)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    
    [theme.breakpoints.down('sm')]: {
        flexDirection: "row",
    }
}));

const MyTitle = styled(`h1`)(({ theme }) => ({
    color: "white",
    fontWeight: "100",
}));

export { MyBanner, MyWrapper, MyTitle }