import React from 'react'
import { MyTypography, MyAppbar, MyContainer, MyLink, MyScrollLink, MyToolbar, MyBox, BrandLink, SideBarIcon, MyPhoneNumber} from "../Assets/Css/NavbarStyles"
import { Drawer, Box, ListItem } from '@mui/material';
import logo from "../Assets/Images/logo.webp"


function Navbar() {
    const [open, setOpen] = React.useState(false);


  return (
        <>      
            <MyAppbar id="nav" position="static">
                <MyToolbar disableGutters={true}>
                    <MyContainer maxWidth='lg' disableGutters={true}>
                    <BrandLink href="/"><img src={logo} alt="logo de la société de dératisation de lille JLM-Multitraitement" className="brandImage"/></BrandLink>
                    <MyBox>
                        <MyTypography variant="h7" component="div">
                            <MyLink href="/actualité">
                                ACTUALITÉ
                            </MyLink>
                        </MyTypography>
                        <MyTypography variant="h7" component="div">
                            <MyScrollLink
                                activeClass="active"
                                to="contact"
                                spy={true}
                                smooth={true}
                                offset={-230}
                                duration={700}                                
                            >
                                CONTACT
                            </MyScrollLink>
                        </MyTypography>
                        <MyTypography variant="h7" component="div">
                            <MyLink href='https://jlm-multi-traitements.gazoleen.com/rdv'>PRENDRE RDV</MyLink>
                        </MyTypography>
                        <MyTypography variant="h6" component="div">
                            <MyPhoneNumber href='/'>03 20 59 07 25</MyPhoneNumber>
                        </MyTypography>
                    </MyBox>
                    

                    <SideBarIcon sx={{color: "black"}} onClick={() => setOpen(true)} />
                    <Drawer open={open} anchor={"left"} onClose={() => setOpen(false)}>
                        <Box>
                            <ListItem>
                                <MyLink href='/actualité'>
                                    ACTUALITÉ
                                </MyLink>
                            </ListItem>
                            <ListItem>
                                <MyScrollLink
                                    activeClass="active"
                                    to="contact"
                                    spy={true}
                                    smooth={true}
                                    offset={0}
                                    duration={700}
                                    onClick={() => setOpen(false)}
                                >
                                    CONTACT
                                </MyScrollLink>
                            </ListItem>
                            <ListItem>
                                <MyLink href='https://jlm-multi-traitements.gazoleen.com/rdv'>PRENDRE RDV</MyLink>
                            </ListItem>
                            <ListItem>
                                <MyLink href='/'>03 20 59 07 25</MyLink>
                            </ListItem>
                        </Box>
                    </Drawer>
                    </MyContainer>
                </MyToolbar>
            </MyAppbar>
        </>
  )
}

export default Navbar