import { Typography, Paper, Box, Link } from '@mui/material'
import { Container } from '@mui/system'
import React from 'react'
import Navbar from '../Layouts/Navbar'
import { Section, Header } from '../Assets/Css/ArticleStyles'
import Footer from '../Layouts/Footer'


function Article(props) {
    let { type } = props

    if (type === 'normal')
        return <ArticleBase {...props} />
    if (type === 'image')
        return <ArticleImage {...props} />
    if (type === 'paragraph and video')
        return <ArticleVideo {...props} />
}

function ArticleBase(props) {

    let { title, bannerImage, author, date, paragraphs} = props

  return (
    <>
        <Navbar />
        <Section>

            <article>

                <Header image={bannerImage}>
                    <Container maxWidth="lg">
                        <Box className='header-wrapper'>
                            <Typography className='article-title' variant='h4'>
                                {title}
                            </Typography>
                        </Box>
                    </Container>
                </Header>

                
                <Container maxWidth="xl">
    
                    <Paper className='article-wrapper' elevation={24}>

                        {paragraphs.map((paragraph) => {
                            if (paragraph.title && paragraph.text && paragraph.link) {
                                return (
                                    <React.Fragment key={paragraph.id}>
                                        <Typography className='paragraph-title' variant='h6'>
                                            {paragraph.title}
                                        </Typography>
                                        <p>{paragraph.text}</p>
                                        <Link href={paragraph.link} className="article-link">{paragraph.linkName}</Link>
                                    </React.Fragment>
                                )
                            }
                            if (paragraph.title && paragraph.text) {
                                return (
                                    <React.Fragment key={paragraph.id}>
                                        <Typography className='paragraph-title' variant='h6'>
                                            {paragraph.title}
                                        </Typography>
                                        <p>{paragraph.text}</p>
                                    </React.Fragment>
                                )
                            }
                             return (
                                <React.Fragment key={paragraph.id}>
                                    <p style={{paddingTop: "1rem"}}>{paragraph.text}</p>
                                </React.Fragment>
                            )
                        })}
                        <Box className='author-wrapper'>
                            <span>Par {author}, {date}</span>
                        </Box>
                    </Paper>

                </Container>

            </article>

        </Section>

        <Footer />
    </>
  )
}

function ArticleVideo(props) {
    let { title, bannerImage, author, date, video, paragraphs} = props

    return (
        <>
            <Navbar />
            <Section>
                <article>
                    <Header image={bannerImage}>
                        <Container maxWidth="lg">
                            <Box className='header-wrapper'>
                                <Typography className='article-title' variant='h4'>
                                    {title}
                                </Typography>
                            </Box>
                        </Container>
                    </Header>

                    
                    <Container maxWidth="xl">
                        <Paper className='article-wrapper' elevation={24}>
                            <Typography className='paragraph-title' variant='h6'>
                                {paragraphs[0].title}
                            </Typography>

                            <Box className='video-wrapper'>
                                <iframe
                                    width="640"
                                    height="360"
                                    src={video}
                                    title="Comment réagir face à la prolifération des rats?"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    allowFullscreen
                                />
                            </Box>

                            <Box className='author-wrapper'>
                                <span>Par {author}, {date}</span>
                            </Box>
                        </Paper>
                    </Container>

                </article>
            </Section>
        </>
    )
}

function ArticleImage(props) {
    let { title, bannerImage, author, date, image, alt} = props

    return (
        <>
            <Navbar />
            <Section>
                <article>

                    <Header image={bannerImage}>
                        <Container maxWidth="lg">
                            <Box className='header-wrapper'>
                                <Typography className='article-title' variant='h4'>
                                    {title}
                                </Typography>
                            </Box>
                        </Container>
                    </Header>


                    <Container maxWidth="xl">
                        <Paper className='article-wrapper' elevation={24}>
                            <img className='article-image' alt={alt} src={require("../Assets/Images/" + image)}/>
                            <Box className='author-wrapper'>
                                <span>Par {author}, {date}</span>
                            </Box>
                        </Paper>
                    </Container>

                </article>
            </Section>


            <Footer />
        </>
    )


}

export default Article