
import styled from "styled-components";

const Section = styled(`section`)(({ theme }) => ({
    paddingBottom: "6rem",
    paddingTop: "6rem",

    "& .title" : {
        paddingBottom: "3rem",
        fontFamily: 'Proxima Nova',
        fontWeight: "bold"
    },
    "& .card": {
        width: "100%",
        height: "100%",
        overflow: "hidden",
        boxShadow: "0 0.1rem 1rem rgb(0 0 0 / 26%);",
        borderRadius: "1em",
        background: "linear-gradient(to right,#FFFFFF,#ECE9E6);"
    },
    "& .card *": {
        color: "black",
        textDecoration: "none",
    },
    "& .card__body": {
        padding: "1rem",
        display: "flex",
        flexDirection: "column",
        gap: ".5rem",
    },
    "& .card__title": {
        fontSize: "1.5rem",
        textTransform: "capitalize",
        lineHeight: "1",
        fontFamily: 'Proxima Nova',
    },
    "& .card__footer": {
        display: "flex",
        padding: "1rem",
        marginTop: "auto",
    },
    "& .user": {
        display: "flex",
        gap: ".5rem"
    },
    "& .user__image": {
        borderRadius: "50%",
    },
    "& .user__info > small": {
        color: "#666"
    }
    

}));

export { Section }