import React from 'react'
import { Box, Container } from '@mui/system'
import { Grid, Link, Typography } from '@mui/material'
import pageNotFound from "../Assets/Images/pageNotFound.webp"

function PageNotFound() {
  return (
    <Box
        sx={{
        display: 'flex',
        alignItems: 'center',
        minHeight: '100vh'
        }}
    >
        <Container maxWidth="md" sx={{
            paddingLeft: '32px'
        }}>
        <Grid container spacing={2}>
            <Grid xs={12}>
            <img
                src={pageNotFound}
                width="100%"
                height="100%"
                alt="Erreur 404 de page non trouvée - Site Web JLM-Multitraitement"
                loading="lazy"
            />
            </Grid>
            <Grid xs={12} sx={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
            }}>
                <Typography variant="h5" sx={{
                    fontFamily: "Proxima Nova",
                    textAlign: "center"
                }}>
                    <strong>
                        La page que vous recherchez n'existe pas...
                    </strong>
                </Typography>
                <Link href='/' sx={{
                    backgroundColor: "green",
                    padding: "10px 20px",
                    color: "white",
                    cursor: "pointer",
                    marginTop: "16px",
                }}>Retournez en lieu sûr</Link>
            </Grid>
        </Grid>
        </Container>
    </Box>
  )
}

export default PageNotFound