import React, { useEffect } from 'react'
import Navbar from '../Layouts/Navbar';
import Banner from '../Layouts/Banner';
import { Container } from '@mui/system';
import styles from '../Assets/Css/ArticleStyles';
import Footer from '../Layouts/Footer';
import { capitalize } from '../Services/capitalize';
import { useParams } from 'react-router-dom';

function DestructionGuepe() {

  let { slug } = useParams();
  
  if (slug)
    slug = capitalize(slug)

  const bannerTitle = slug ? `Destruction de nid de guepes ou frelons à ${slug}` : 'Destruction de nid de guepes ou frelons'
  const title = slug ? `Destruction de nid de guepes ou frelons à ${slug} | JLM Multitraitement` : "Destruction de nid de guepes ou frelons | JLM Multitraitement"

  useEffect(() => {
    document.title = title;
  }, [title])


  return (
    <>
        <Navbar />
        <Banner title={bannerTitle}/>
    
        <div style={styles.spacer}></div>

        <Container maxWidth="lg">
            <p style={styles.spacing}>JLM met à la disposition des entreprises ainsi que des particuliers un savoir faire unique dans le traitement et dans la destruction des nids de guêpes et / ou de frelons.</p>
            <p style={styles.spacing}>Ces insectes représentent un réel danger dans la vie au quotidien : piqûres, désagrément, invasion (…). La présence de ces nids ne doivent pas être pris à la légère et doivent être signalés rapidement auprès des entreprises compétentes pour les manipuler et les détruire. Sans cela, vous courrez un réel danger qui peut être mortel même s’il est situé en extérieur du local concerné.</p>
            
            <h2 style={styles.spacing}>1) Des professionnels formés pour vous débarrasser de ce danger</h2>
            <p style={styles.spacing}>Dans un premier temps, il est très formellement déconseillé de manipuler ces nids par vos propres moyens : d’une part il vous faut un équipement spécialement étudié et d’autre part procéder à des gestes précis pour ne pas se faire attaquer par ces insectes.</p>
            <p style={styles.spacing}>C’est pourquoi nos techniciens sont spécialement formés pour intervenir à votre place : ils procèdent aux extractions directement à la main quand le nid est suffisamment accessible, à l’aide d’une échelle lorsqu’il est situé dans une toiture et peut être poussé jusqu’à l’utilisation de drôle quand il se situe dans des endroits dont l’accès est difficile.</p>
            <p style={styles.spacing}>En fonction de l’emplacement, les biocides utilisés sont spécifiquement étudiés pour lutter contre ces phénomènes : vous ne les trouverez ni dans le commerce, ni dans les magasins spécialisés. Seuls les professionnels sont habilités à les utiliser car ils disposent d’une grande dangerosité. Ils peuvent être appliqués sous forme de poudre ou sous forme liquide : le technicien seul décidera de l’application en fonction de la situation. Si c’est possible, il pourra le déloger de façon manuelle sans utiliser le moindre produit chimique.</p>
        
            <h2 style={styles.spacing}>2) Une intervention rapide et efficace</h2>
            <p style={styles.spacing}>A partir du moment ou l’un de nos technicien prend en charge la présence d’un nid de guêpes ou de frôlons, les résultats seront visibles de façon très rapide ! En général, une seule intervention suffit et il n’est pas nécessaire de répéter l’application du produit.</p>
            <p style={styles.spacing}>Après son départ, 90% des insectes seront exterminés et délogés, pour atteindre les 100% dès le lendemain qui suit l’intervention !</p>
            <p style={styles.spacing}>Vous bénéficiez d’une solution très rapidement à votre désagrément et si vous le souhaitez, nous pouvons procéder à des visites de contrôle ultérieures pour écarter tout risque de récidive.</p>
            <p style={styles.spacing}>Nous sommes aptes à vous proposer ce service pour une maison, un immeuble, un local professionnel ou un terrain extérieur (arbre, poteau), et ce, même si les nids sont situés dans des accès difficiles. Nous avons le matériel adéquate pour vous permettre de retrouver un esprit tranquille sans prendre le risque de vous mettre en danger !</p>
            
            <h2 style={styles.spacing}>3) Un devis sur mesure</h2>
            <p style={styles.spacing}>Pour obtenir un devis gratuitement et sans engagements, n’hésitez pas à nous contacter. Nous vous soumettrons une proposition sur mesure et entièrement personnalisé selon les méthodes utilisées. Les travaux ne seront entamés seulement avec votre accord. Alors ne prenez pas de risques inutiles !</p>

            <div style={styles.spacer}></div>
        </Container>

        <Footer />
    </>
  )
}

export default DestructionGuepe