import styled from "styled-components";

const spacing = {
    "marginBottom": "2em",
}

const spacer = {
    "marginBottom": "5em"
}

const styles = {
    spacing: spacing,
    spacer: spacer
}


const Section = styled(`section`)(({ theme }) => ({
    paddingBottom: "6rem",
    "& .article-title" : {
        fontFamily: 'Proxima Nova Bold',
        fontWeight: "900",
        color: "white"
    },
    "& .paragraph-title" : {
        fontFamily: 'Proxima Nova',
        fontWeight: "900",
        color: "#484848",
        paddingTop: "1rem",
    },
    "& .article-wrapper" : {
        padding: "4rem",
        paddingTop: "6rem",
        marginTop: "-110px",
        "@media (max-width: 767px)" : {
            padding: "1.5em",
            paddingTop: "2em",
        }
    },
    "& .article-image" : {
        display: "block",
        marginRight: "auto",
        marginLeft: "auto"
    },
    "& .author-wrapper" : {
        paddingTop: "4rem",
        "& .divider" : {
            backgroundColor: "black",
            width: "10px",
            
        }
    },
    "& .video-wrapper" : {
        position: "relative",
        marginTop: "2rem",
        paddingBottom: "56.25%",
        overflow: "hidden"
    },
    "& .video-wrapper iframe": {
        overflow: "hidden",
        border: "0",
        alignSelf: "center",
        position: "absolute",
        top: "0",
        left: "0",
        width: "100%",
        height: "100%"
    },
    "& .article-link" : {
        "marginTop": "10rem",
    }
}));

const Header = styled(`header`)(({ image }) => ({
    backgroundImage: "url(" + require("../Images/" + image) + ")",
    backgroundBlendMode: "darken",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundColor: "rgb(0 0 0/0.5)",
    height: "460px",
    display: "flex",
    alignContent: "center",
    flexWrap: "wrap",

    "& .container" : {
        display: "flex",
    },
    "& .header-wrapper" : {
        display: "flex",
        justifyContent: "center",
    }
}));


export { Section, Header }
export default styles