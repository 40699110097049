import { Grid, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import { Link } from 'react-router-dom'

function ArticleCard(props) {

    let {bannerImage, title, date, paragraphs, slug, author} = props
    let info = ""
    if (paragraphs) {
      if (paragraphs[0].text)
        info = paragraphs[0].text.substring(0, 100) + "..."
      if (paragraphs[0].title)
        info = paragraphs[0].title.substring(0, 100) + "..."
    }

    if (title.length > 55) 
      title = title.substring(0, 55) + "..."

  return (
    <Grid item lg={4} md={6} sm={6}>
        <Box className="card">
          <Link to={slug}>
          <Box className="card__header">
            <img src={require("../Assets/Images/" + bannerImage)} alt="card__image" className="card__image" width="100%" />
          </Box>
          <Box className="card__body">
            <Typography className='card__title'>{title}</Typography>
            <p>{info}</p>
          </Box>
          <Box className="card__footer">
            <Box className="user">
              <img src="https://www.pngitem.com/pimgs/m/150-1503945_transparent-user-png-default-user-image-png-png.png" width={40}  alt="user__image" className="user__image" />
                <Box className="user__info">
                  <h5>{author}</h5>
                  <small>{date}</small>
                </Box>
            </Box>
          </Box>
          </Link>
        </Box>
    </Grid>
  )
}

export default ArticleCard