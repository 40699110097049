import React from 'react'
import Contact from '../Layouts/Contact';
import FeedBackBanner from '../Layouts/FeedBackBanner';
import HeroBanner from '../Layouts/HeroBanner';
import Navbar from '../Layouts/Navbar';
import Service from '../Layouts/Service';
import Footer from '../Layouts/Footer';
import BannerIntervention from '../Layouts/BannerIntervention';
import Partner from '../Layouts/Partner';
import About from '../Layouts/About';

function Home() {
  return (
    <>
        <Navbar />
        <HeroBanner />
        <About />
        <Service />
        <FeedBackBanner />
        <Contact />
        <BannerIntervention />
        <Partner />
        <Footer />
    </>
  )
}

export default Home;