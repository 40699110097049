import { Grid, Typography } from '@mui/material'
import { Container } from '@mui/system'
import React from 'react'
import Footer from '../Layouts/Footer'
import Navbar from '../Layouts/Navbar'
import articlesData from "../data/articlesData.json"
import ArticleCard from './ArticleCard'
import { Section } from '../Assets/Css/BlogStyle'

function Blog() {
  
  return (
    <>
    <Navbar />
    <Section>
      <Container maxWidth="lg">
          <Typography variant="h4" className='title'>Nos Dernières Actualités</Typography>
          <Grid container spacing={2}>
              {articlesData.map((article) => {
                return (
                  <ArticleCard key={article.id} {...article} />
                )
              })}
          </Grid>
          {/* <Pagination count={10} size="large" /> */}
      </Container>
    </Section>
    <Footer />
    </>
  )
}

export default Blog