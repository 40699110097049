import { Grid } from "@mui/material";
import styled from "styled-components";

const Section = styled(`section`)(({ theme }) => ({
    paddingTop: "7em",
    paddingBottom: "7em",

    "& .title" : {
        textAlign: "center",
        paddingBottom: "20px",
        textTransform: "uppercase",
    },
    "& .grid" : {
        justifyContent: "center",
        alignItems: "center"
    },
}));

const Item = styled(Grid)(({ theme }) => ({
    display: "flex",
    justifyContent: "center",
}))

export { Section, Item }