import React from 'react'
import { Container, Grid, Typography } from "@mui/material";
import { MyWrapper, MyPhoneWrapper, MyMenu, MyMenuItem, MyMenuTitle, MyTypography } from '../Assets/Css/FooterStyles';

function Footer() {
  return (
    <MyWrapper>
      <Container maxWidth="lg">
      <Grid container alignItems="center">

        <Container maxWidth="lg">
            <MyPhoneWrapper item lg={12}>
                <Typography color="white" variant="h4">
                    03 20 59 07 25
                </Typography>
            </MyPhoneWrapper>
        </Container>


        <Grid sx={{padding: "20px"}} item xs={12} md={4}>
          <MyMenu>
            <MyMenuTitle variant="h6">
                Jlm Multitraitement
            </MyMenuTitle>
            <MyTypography variant="h7">
            Spécialiste expérimenté de la désinfection, de la désinsectisation et de la dératisation, JLM vous propose des services visant à éradiquer définitivement tous les nuisibles existants dans vos espaces privés et publics dans toutes la région du Nord et vous assure un service discret, de qualité et respectueuse de votre environnement
            </MyTypography>
          </MyMenu>
        </Grid>

        <Grid sx={{padding: "20px"}} item xs={12} md={4}>
          <MyMenu>
            <MyMenuTitle variant="h6">
                Nous trouvez
            </MyMenuTitle>
            <MyTypography variant="h7">
                288 Rue de la Carnoy,
            </MyTypography>
            <MyTypography sx={{marginBottom: "16px"}} variant="h7">
                59130 Lambersart, France.
            </MyTypography>
            <MyTypography variant="h7">
                Tel: 03 20 59 07 25
            </MyTypography>
            <MyTypography variant="h7">
                Email: contact@multitraitements.fr
            </MyTypography>
          </MyMenu>
        </Grid>

        <Grid sx={{padding: "20px"}} item xs={12} md={4}>
          <MyMenu>
            <MyMenuTitle variant="h6">
                Liens Utiles
            </MyMenuTitle>
            <Typography variant="h7">
                <MyMenuItem href="/">Accueil</MyMenuItem>
            </Typography>
            <Typography variant="h7">
                <MyMenuItem href="#nosServices">Nos Services</MyMenuItem>
            </Typography>
            <Typography variant="h7">
                <MyMenuItem href="#avis">L'avis de nos client</MyMenuItem>
            </Typography>
            <Typography variant="h7">
                <MyMenuItem href="#avis">Contactez-Nous</MyMenuItem>
            </Typography>
            <Typography variant="h7">
                <MyMenuItem href="https://jlm-multi-traitements.gazoleen.com/rdv">Prendre Rendez-Vous</MyMenuItem>
            </Typography>
          </MyMenu>
        </Grid>

        <Grid item xs={12} md={4}>
          <Typography color="white" variant="subtitle1">
            {`© ${new Date().getFullYear()} JLM Multi-Traitement`}
          </Typography>
        </Grid>

      </Grid>
    </Container>
  </MyWrapper>
  )
}

export default Footer