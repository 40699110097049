import { Container, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Link } from "react-scroll";

const MyImage = styled(`img`)(({ theme }) => ({
    width: "100%",
    height: "100%",
    objectFit: "cover",
    objectPosition: "top",
    filter: "brightness(0.5)",
    [theme.breakpoints.down('sm')]: {
        filter: "brightness(0.5)"
    }
}));

const MyWrapper = styled(`div`)(({ theme }) => ({
    height: "calc(100vh - 88px)",
}));

const MyLink = styled(Link)(({ theme }) => ({
    color: "white",
    textDecoration: "none",
    fontFamily: 'Proxima Nova Bold',
    background: "rgb(121, 200, 100)",
    padding: "16px 32px",
    marginTop: "2em",
    cursor: "pointer",
    "&:hover": {
        background: "rgb(123, 208, 149)",
    },
}));

const MyContainer = styled(Container)(({ theme }) => ({
    [theme.breakpoints.down('sm')]: {
        marginLeft: "16px"
    }
}));

const MyGrid = styled(Grid)(({ theme }) => ({
    position: "absolute",
    top: "40%"
}));

const MyTitle = styled(`h1`)(({ theme }) => ({
    color: "white",
    marginBottom: "0.4em",
}));

const MySubTitle = styled(`h2`)(({ theme }) => ({
    color: "white",
    marginBottom: "0.4em",
}));

const MyText = styled(`h3`)(({ theme }) => ({
    color: "white",
    marginBottom: "2em",
}));

export {
    MyImage,
    MyWrapper,
    MyLink,
    MyGrid,
    MyTitle,
    MySubTitle,
    MyText,
    MyContainer
}