import { styled } from '@mui/material/styles';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneIcon from '@mui/icons-material/Phone';
import SendIcon from '@mui/icons-material/Send';
import PublicIcon from '@mui/icons-material/Public';
import { Grid, Box, Button, Container } from '@mui/material';


const LeftContent = styled(Grid)(({ theme }) => ({
    background: "rgb(123, 208, 149)",
    padding: "1.5rem",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
}));

const LeftContentBox = styled(Box)(props => ({
    display: "flex",
    marginBottom: props.last === "true" ? '0px' : '20px',
    alignItems: "center"

}));

const LeftContentText = styled(`p`)(({ theme }) => ({
    paddingLeft: "1rem",
    color: "white"
}));

const MyPhoneIcon = styled(PhoneIcon)(({ theme }) => ({
    color: "white",
}));

const MyLocationOnIcon = styled(LocationOnIcon)(({ theme }) => ({
    color: "white",
}));

const MySendIcon = styled(SendIcon)(({ theme }) => ({
    color: "white",
}));

const MyPublicIcon = styled(PublicIcon)(({ theme }) => ({
    color: "white",
}));

const MyIconWrapper = styled(`div`)(({ theme }) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "50px",
    height: "50px",
    borderRadius: "50%",
    border: "2px solid rgba(255, 255, 255, 0.2)"
}));

const MyContainer = styled(Container)(({ theme }) => ({
    paddingTop: "7em",
    paddingBottom: "7em",
    [theme.breakpoints.down('sm')]: {
        paddingRight: "0"
    }
}));

const MyButton = styled(Button)(({ theme }) => ({
    color: "white",
    background: "rgb(123, 208, 149)",
    marginTop: "20px"
}));

const RightContent = styled(Grid)(({ theme }) => ({
    [theme.breakpoints.down('sm')]: {
        paddingRight: "16px"
    }
}));


export {
    LeftContent,
    LeftContentBox,
    LeftContentText,
    MyPhoneIcon,
    MyLocationOnIcon,
    MySendIcon,
    MyPublicIcon,
    MyIconWrapper,
    MyButton,
    MyContainer,
    RightContent
}