import styled from "styled-components"

const Section = styled(`section`)(({ theme }) => ({
    paddingBottom: "10rem",
    paddingTop: "7rem",

    "& .image-section img" : {
        width: "100%",
        height: "auto",
        borderRadius: "10px"
    },
    "& .content-section .title" : {
        fontSize: "28px",
        textTransform: "uppercase"
    },
    "& .content-section .content h5" : {
        marginTop: "20px",
        color: "#5d5d5d",
        fontSize: "21px"
    },
    "& .content-section .content p" : {
        marginTop: "10px",
        fontSize: "17px",
        lineHeight: "1.3"
    }
}));

export { Section }