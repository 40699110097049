import { Grid, Typography } from '@mui/material'
import { Box, Container } from '@mui/system'
import React from 'react'
import logoEngie from "../Assets/Images/logoEngie.webp"
import logoSncf from "../Assets/Images/logoSncf.webp"
import { Section, Item } from '../Assets/Css/PartnerStyles'

function Partner() {
  return (
    <>
      <Section>
        <Typography variant='h4' className='title'>
          <strong>Nos Partenaires</strong>
        </Typography>
        <Container >
          <Box>
            <Grid container spacing={12} className="grid">
              <Item item xs={6} md={3}>
                <img width="100%" height="100%" alt="Logo du partenaire Engie" src={logoEngie}/>
              </Item>
              <Item className='item' item xs={6} md={3}>
                <img width="100%" height="100%" alt="Logo du partenaire Sncf" src={logoSncf}/>
              </Item>
            </Grid>
          </Box>
        </Container>
    </Section>
    </>
  )
}

export default Partner