import React from 'react'
import Navbar from '../Layouts/Navbar';
import Banner from '../Layouts/Banner';
import { Container } from '@mui/system';
import styles from '../Assets/Css/ArticleStyles';
import Footer from '../Layouts/Footer';
import { useParams } from 'react-router-dom';
import { capitalize } from '../Services/capitalize';
import { useEffect } from 'react';

function TraitementTaupe() {

  let { slug } = useParams();

  if (slug)
    slug = capitalize(slug)

  const bannerTitle = slug ? `Traitement contre les taupes à ${slug}` : 'Traitement contre les taupes'
  const title = slug ? `Traitement contre les taupes à ${slug} | JLM Multitraitement` : "Traitement contre les taupes | JLM Multitraitement"


  useEffect(() => {
    document.title = title;
  }, [title])

  return (
    <>
        <Navbar />
        <Banner title={bannerTitle}/>
    
        <div style={styles.spacer}></div>

        <Container maxWidth="lg">
            <p style={styles.spacing}>Vous trouverez un peu près toutes les solutions possibles et imaginables pour le traitement des taupes. A terme, vous allez perdre du temps, de l’énergie et de l’argent puisqu’il existe qu’une seule solution radicale qui permette de les exterminer définitivement.</p>            
            
            <h2 style={styles.spacing}>1) JLM : une société spécialisée</h2>
            <p style={styles.spacing}>JLM est une entreprise au service des particuliers et des professionnels qui fournit des prestations efficace dans le traitement contre les taupes.</p>
            <p style={styles.spacing}>Pour ce faire, nous procédons par la pose de petits pièges appelés « Putange », dans 99 % des cas et par le gazage des galeries dans seulement 1% des cas.</p>
            <p style={styles.spacing}>Cette méthode est très efficace est a su faire ses preuves puisque les résultats se font connaître dans les 48 à 72 heures qui suivent l’intervention des techniciens JLM.</p>
            <p style={styles.spacing}>Ces services sont ouverts à tous les particuliers, les professionnels ainsi que les collectivités locales qui souhaitent se débarrasser des taupes qui nuisent à leurs jardins.</p>

            <h2 style={styles.spacing}>2) Choisissez une méthode radicale</h2>
            <p style={styles.spacing}>Malgré les conseils les plus farfelus que vous trouverez sur internet ou dans votre entourage, les pesticides que vous trouverez dans le commerce ne vous seront d’aucune utilité. Contrairement aux idées reçues, les taupes ne sont pas hémophiles, n’ont pas peur du bruit et sont en activité tout au long de l’année.</p>
            <p style={styles.spacing}>De plus, ils sont très onéreux et ne respectent pas l’environnement : c’est pourquoi il vous faut prendre les choses en main en vous adressant à des professionnels et qui ont fait du traitement des taupes leur métier.</p>
            <p style={styles.spacing}>Comme le dit le diction, mieux vaut prévenir que guérir : dès les premiers signes, contactez nous pour que nos techniciens puissent intervenir avant que le problème ne prenne une plus grande ampleur et cause davantage de dégâts.</p>
            <p style={styles.spacing}>Pour répondre à toutes les demandes ainsi qu’à tous les besoins, nous avons mis en place trois offres :</p>
            <ul>
                <li>Un seul passage</li>
                <li>Une série de trois passages qui sous espacés d’une période de 15 jours</li>
                <li>Plusieurs passages planifiés à l’année.</li>
            </ul>
            <p style={styles.spacing}>Quelle que soit la formule que vous souhaitez opter, vous pouvez compter sur le conseil et le diagnostique de notre expert pour adopter le meilleur choix et la méthode la plus adaptée à votre situation.</p>
            
            <h2 style={styles.spacing}>3) Combien ça coute ?</h2>
            <p style={styles.spacing}>Chaque proposition de JLM est établi sur mesure via un devis entièrement personnalisé : une fois que vous prenez contact avec nous, nous envoyons un technicien sur les lieux concernés.</p>
            <p style={styles.spacing}>Il va procéder à un diagnostique en prenant plusieurs éléments en compte : nombre de nuisibles, étendue des dégâts, superficie à traiter et choix du matériel adéquate. En fonction de ces éléments, le des pourra être établit et si cela vous convient, il vous suffira de le signer pour marquer votre accord pour que le technicien puisse commencer les travaux.</p>
            <p style={styles.spacing}>Les taupes sont un problème à prendre au sérieux et le plus rapidement possible afin de limiter leur reproduction et les dégâts dans votre jardin ! Nous sommes à votre écoute pour toute demande complémentaire.</p>

            <div style={styles.spacer}></div>
        </Container>

        <Footer />
    </>
  )
}

export default TraitementTaupe