import React, { useEffect } from 'react'
import Navbar from '../Layouts/Navbar';
import Banner from '../Layouts/Banner';
import { Container } from '@mui/system';
import styles from '../Assets/Css/ArticleStyles';
import Footer from '../Layouts/Footer';
import { useParams } from 'react-router-dom';
import { capitalize } from '../Services/capitalize';

function TraitementAcariens() {

  let { slug } = useParams();

  if (slug)
    slug = capitalize(slug)

  const bannerTitle = slug ? `Traitement contre les acariens à ${slug}` : 'Traitement contre les acariens'
  const title = slug ? `Traitement contre les acariens à ${slug} | JLM Multitraitement` : "Traitement contre les acariens | JLM Multitraitement"


  useEffect(() => {
    document.title = title;
  }, [title])

  return (
    <>
        <Navbar />
        <Banner title={bannerTitle}/>
    
        <div style={styles.spacer}></div>

        <Container maxWidth="lg">
            <p style={styles.spacing}>Les acariens sont des nuisibles qui ne sont pas visibles à l’oeil nu : ils font leurs nids dans la literie, dans les placards et dans les bois. Ils ne sont d’ordinaire pas offensifs pour l’homme (à l’exception que vous ne soyez pas allergique) mais peuvent en revanche véhiculer des maladies de la peau et abîmer vos meubles. Afin de ne pas attendre que les colonnes deviennent trop nombreuses, il est important de prendre le problème au sérieux et de faire intervenir un professionnel comme la société JLM par exemple.</p>            
            
            <h2 style={styles.spacing}>1) Préservez votre santé</h2>
            <p style={styles.spacing}>Les débris des acariens ainsi que leurs excréments sont la source qui provoquent les allergies chez l’homme et peuvent évoluer vers des formes plus graves comme les pathologies respiratoires (asthme). On peut trouver dans le commerce des matelas anti-acariens spécialement étudiés pour permettre un milieu hostile à ces nuisibles mais il faut savoir qu’ils sont non seulement couteux, mais ils leur moyen d’action a une durée de vie limitée et représente un risque de toxicité.</p>
            <p style={styles.spacing}>Pour avoir l’esprit serein et tranquille, mieux vaut opter pour une solution radicale mais efficace : faire appel à une entreprise spécialisée dans leur extermination.</p>

            <h2 style={styles.spacing}>2) Quel est le service des professionnels?</h2>
            <p style={styles.spacing}>Les entreprises comme JLM procèdent à la désinsectisation et à l’assainissement des vos biens : nos experts disposent d’un savoir faire et d’une expérience significative dans ce domaine afin de proposer au client un service performant et satisfaisant.</p>
            <p style={styles.spacing}>En fonction de la nature de vos besoins et de vos demandes, un technicien est envoyé directement sur les lieux concerné pour pouvoir évaluer l’étendue des travaux à effectuer. Il vous proposera alors une solution adaptée : traitement par pulvérisation au sol de produit insecticide, nettoyage manuel des rampes, des poignées de portes, des tiroirs, de meubles en bois etc.</p>
            <p style={styles.spacing}>A chaque situation, il y a une solution adaptée : c’est pourquoi vous bénéficierez d’une intervention sur mesure et entièrement adaptée à votre maison ou votre local professionnel.</p>

            
            <h2 style={styles.spacing}>3) Combien ça coûte ?</h2>
            <p style={styles.spacing}>Les devis de l’entreprise JLM sont entièrement réalisés sur mesure : c’est pourquoi il n’existe pas de tarif moyen. Il dépend de la surface à traiter, de la quantité de produit à utiliser et de l’évolution des colonies des nuisibles. S’il y en a peu, un seul passage suffira, et si la situation est évoluée, il faudra prévoir deux interventions au grand maximum.</p>
            <p style={styles.spacing}>Les produits utilisés (des biocides liquides) sont respectueux de l’environnement contrairement à ceux que l’on trouve dans le commerce et de plus, ils ne nuisent pas à votre santé, ni à celle de votre animal de compagnie (chien, chat). Certaines précautions seront tout de même de rigueur et vous seront détaillées par votre technicien affecté afin de ne courir aucun risque pour vous ou pour vos proches.</p>
            <p style={styles.spacing}>Si vous souhaitez obtenir votre devis, il suffit de nous appeler pour que nous puissions vous envoyer un professionnel qui procèdera un diagnostique. C’est gratuit et sans engagement alors pour assainir votre maison, mieux vaut miser sur une solution sûre et entièrement fiable !</p>

            <div style={styles.spacer}></div>
        </Container>

        <Footer />
    </>
  )
}

export default TraitementAcariens