import { Button, Typography, Box } from '@mui/material';
import { styled } from '@mui/material/styles';


const MyTitle = styled(Typography)(({ theme }) => ({
    color: "white"
}));

const MyBox = styled(Box)(({ theme }) => ({
    paddingTop: "40px",
    paddingBottom: "40px",
    backgroundColor: "#7bd095",
    textAlign: "center"
}));

const MyButton = styled(Button)(({ theme }) => ({
    color: "white",
    border: "1px solid white",
    marginTop: "20px",
    "&:hover": {
        border: "1px solid white"
    }
}));

const MyLink = styled(`a`)(({ theme }) => ({
    color: "white",
    textDecoration: "none"
}));

export {
    MyBox, 
    MyTitle,
    MyLink,
    MyButton
}