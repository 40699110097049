import React, { useState } from 'react'
import TextField from '@mui/material/TextField';
import emailjs from 'emailjs-com'
import { Snackbar, Alert, FormHelperText } from '@mui/material';

import {
    MyButton
} from '../Assets/Css/ContactStyles';


function ContactForm() {
    const initialFormValues = {
        name: "",
        mail: "",
        phone: "",
        message: ""
    };

    const [open, setOpen] = useState(false)
    const [errorField, setErrorField] = useState("")
    const [message, setMessage] = useState("")
    const [formData, setFormData] = useState(initialFormValues)

    const handleClose = (reason) => {
        if (reason === 'clickaway') {
          return;   
        }
        setOpen(false);
    };
    
    const handleChange = (e) => {
        setFormData({...formData, [e.target.name]: e.target.value})
    }

    const handleSubmit = (event) => {
        event.preventDefault();

        const serviceId = 'service_wnvb6jm'
        const templateId = 'template_9hklnda'
        const publicKey = 'Q6ltym97DnaQqdX9a'
        //eslint-disable-next-line
        const mailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        const phoneRegex = /^(?:(?:\+|00)33[\s.-]{0,3}(?:\(0\)[\s.-]{0,3})?|0)[1-9](?:(?:[\s.-]?\d{2}){4}|\d{2}(?:[\s.-]?\d{3}){2})$/

        if (!mailRegex.test(formData.mail)) {
            setMessage("Rentrer un email valide")
            setErrorField("mail")
            return
        }
        if (!phoneRegex.test(formData.phone)) {
            setMessage("Rentrer numéro de téléphone valide")
            setErrorField("phone")
            return
        }

        emailjs.send(serviceId, templateId, formData, publicKey)
        .then((result) => {
            console.log(result)
            setMessage("")
            setErrorField("")
            setFormData(initialFormValues)
            setOpen(true)
        }, (error) => {
            console.log(error.text);
        });
    };

    return (
        <>
            <Snackbar open={open} onClose={handleClose} autoHideDuration={2000}>
                <Alert severity="success" onClose={handleClose} sx={{ width: '100%' }}>
                    Message Envoyé !
                </Alert>
            </Snackbar>

            <form>
                <TextField required id="name" name='name' label="Nom" value={formData.name} onChange={handleChange} type="text" variant="standard" fullWidth margin="dense"/>
                {errorField === "name" &&
                    <FormHelperText sx={{color: "red"}}>{message}</FormHelperText>
                }

                <TextField required id="mail" name='mail' label="Email" value={formData.mail} onChange={handleChange} type="email"  variant="standard" fullWidth margin="dense"/>
                {errorField === "mail" &&
                    <FormHelperText sx={{color: "red"}}>{message}</FormHelperText>
                }

                <TextField required id="phone" name="phone" label="Telephone" value={formData.phone} onChange={handleChange} type="number" variant="standard" fullWidth margin="dense" inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}/>
                {errorField === "phone" &&
                    <FormHelperText sx={{color: "red"}}>{message}</FormHelperText>
                }

                <TextField
                    required
                    id="message"
                    label="Votre commentaire"
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    type="text"
                    multiline
                    rows={4}
                    variant="standard"
                    fullWidth
                    margin="dense"
                />
                {errorField === "message" &&
                    <FormHelperText sx={{color: "red"}}>{message}</FormHelperText>
                }

                <MyButton variant="contained" onClick={handleSubmit}>
                    Envoyer
                </MyButton> 
            </form>
        </>
    )
}

export default ContactForm