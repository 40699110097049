import React from 'react'
import { MyBanner, MyWrapper, MyTitle } from "../Assets/Css/BannerStyles"

function Banner(props) {
  return (
    <MyBanner container>
        <MyWrapper>
            <MyTitle>
                {props.title}
            </MyTitle>
        </MyWrapper>
    </MyBanner>
  )
}

export default Banner