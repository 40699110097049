import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import souris from "../Assets/Images/souris.webp"
import guepe from "../Assets/Images/guepe.webp"
import desinsectisation from "../Assets/Images/desinsectisation.webp"
import pigeon from "../Assets/Images/pigeon.webp"
import taupe from "../Assets/Images/taupe.webp"
import acarien from "../Assets/Images/acarien.webp"
import React from 'react'
import { Container } from '@mui/system';

import { MyTitle, MyBox, MyIconBtn, MyImageTitle, MyImageList } from '../Assets/Css/ServiceStyles';


export default function TitlebarImageList() {
  return (
    <Container id="nosServices">
      <MyBox>
        <MyTitle variant='h4'><strong>Je souhaite une intervention pour</strong></MyTitle>
      <MyImageList gap={12}>
        {itemData.map((item) => (
          <ImageListItem key={item.img}>
            <img
              src={`${item.img}?w=248&fit=crop&auto=format`}
              srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
              alt={item.alt}
              loading="lazy"
              width="100%"
              height="100%"
            />
            <ImageListItemBar
              title={<MyImageTitle href={item.path}>{item.title}</MyImageTitle>}
              actionIcon={
                <IconButton
                  sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                  aria-label={`info about ${item.title}`}
                >
                  <MyIconBtn href={item.path}>
                  <InfoIcon />
                  </MyIconBtn>
                </IconButton>
              }
            />
          </ImageListItem>
        ))}
      </MyImageList>
      </MyBox>
    </Container>
  );
}

const itemData = [
    {
      img: souris,
      title: 'Dératisation de rats et de souris',
      alt: 'Une souris dans un jardin',
      path: '/deratisation-des-rats-et-souris'
    },
    {
      img: guepe,
      title: 'Destruction de nids de guêpes ou de frelons',
      alt: "Une guêpes qui nuit à une maison",
      path: '/destruction-nids-de-guepes-et-frelons'
    },
    {
      img: desinsectisation,
      title: 'Désinsectisation',
      alt: "Un dératiseur de JLM effectue une desinsectisation dans une maison",
      path: "/désinsectisation"
    },
    {
      img: pigeon,
      title: 'Dépigeonnage',
      alt: "Un pigeon dans un jardin dépigeonnage",
      path: '/depigeonnage'
    },
    {
      img: taupe,
      title: 'Traitement contre les taupes',
      alt: 'Une taupe dans un trou de jardin',
      path: '/traitement-des-taupes'
    },
    {
      img: acarien,
      title: 'Traitement contre les acariens',
      alt: "Un acariens sur une feuille de jardin",
      path: '/traitement-des-acariens'
    }
  ];