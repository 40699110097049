import React from 'react'
import Navbar from '../Layouts/Navbar';
import Banner from '../Layouts/Banner';
import { Container } from '@mui/system';
import styles from '../Assets/Css/ArticleStyles';
import Footer from '../Layouts/Footer';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { capitalize } from '../Services/capitalize';

function SolutionVolatile() {
  
  let { slug } = useParams();

  if (slug)
    slug = capitalize(slug)

  const bannerTitle = slug ? `Dépigeonnage à ${slug}` : 'Dépigeonnage'
  const title = slug ? `Dépigeonnage à ${slug} | JLM Multitraitement` : "Dépigeonnage | JLM Multitraitement"


  useEffect(() => {
    document.title = title;
  }, [title])

  return (
    <>
        <Navbar />
        <Banner title={bannerTitle}/>

        <div style={styles.spacer}></div>

        <Container maxWidth="lg">
            <p style={styles.spacing}>Les volatiles, et notamment les pigeons, sont un véritable fardeau pour les habitations ainsi que les façades des immeubles. Ils provoquent des salissures avec des excréments et se nichent dans les greniers où ils font leur nid. De plus, ils rendent l’entretien des toits difficile et cela provoque des coûts d’entretien supplémentaires. Si vous êtes un particulier ou un professionnel qui avez besoin de trouver une solution à ce désagrément, JLM est le prestataire idéal.</p>
            
            <h2 style={styles.spacing}>1) Une technique efficace et instantanée</h2>
            <p style={styles.spacing}>Les techniciens de JLM procèdent à une capture avec des cages de fabrication artisanale : la cage est sur mesure pour accroitre l’efficacé sur les toitures et sur tous les types de bâtiment. Pour éviter tout forme de récidive, ils posent ensuite des pics ou des câbles très discrets qui empêchent les volatiles de se poser sur les toits et de les détériorer.</p>
            <p style={styles.spacing}>Dans un premier temps, nous procédons par la capture par cage, il faut compter une durée d’action de un à deux mois : c’est une méthode écologique et respectueuse de l’environnement. Aucun mal n’est affligé à l’animal, qui est relâché dans un endroit où il ne peut pas nuire et où il n’y a pas de source de danger pour lui. Nos techniciens se rendent sur les lieux régulièrement pour relever les cages et les remplacer par de nouvelles jusqu’à ce que les volatiles ne s’y posent plus.</p>
            <p style={styles.spacing}>Ensuite, nous mettons en place la méthode par cable dont l’effet est instantané : vous serez débarrassé des volatiles dès que la pose sera terminée. Les dispositifs sont garantis pendant une période de deux ans à compter de l’installation : s’ils sont endommagés par des intempéries, ils seront remplacés dans la foulée sur une simple demande de votre part.</p>
        
            <h2 style={styles.spacing}>2) Un devis adapté</h2>
            <p style={styles.spacing}>Chez JLM, nous procédons au plus simple pour vous rendre service : vous nous contactez par téléphone pour nous expliquer la problématique, nous envoyons un technicien ainsi qu’un expert usr les lieux de votre choix afin qu’il réalise un audit précis. Avec les éléments qu’ils auront recueilli, il pourront vous proposer un devis gratuit et sur mesure en fonction de vos besoins.</p>
      
            <h2 style={styles.spacing}>3) Un processus simple</h2>
            <p style={styles.spacing}>Le déroulement des opérations pour vous débarrasser définitivement des volatiles est simple : la capture par cage dans un premier temps puis l’installation des filets pour éviter les intrusions futures.</p>
            <p style={styles.spacing}>Nous proposons également d’autres prestations optimisées : les pics anti-pigeons pour empêcher les nidifications, la pose des cibles anti-pigeons pour qu’ils ne puissent plus se poser sur votre toit. Avec JLM, vous aurez même la possibilité de désinfecter des surfaces ou des zones insalubres dont l’état aura été causé par les excréments ainsi que les carcasses des animaux morts.</p>
            <p style={styles.spacing}>Ainsi, il est simple de mettre fin à une situation délicate et dire adieu aux volatiles qui salissent votre façade et vos toits. Faîtes le choix d’une entreprise fiable et expérimentée pour confier vos travaux de protection. Choisissez JLM et son équipe d’experts à votre service. Pour faire votre demande de devis gratuit, contactez nous par téléphone ou par email !</p>

            <div style={styles.spacer}></div>
        </Container>

        <Footer />
    </>
  )
}

export default SolutionVolatile