import React from 'react'
import Navbar from '../Layouts/Navbar';
import Banner from '../Layouts/Banner';
import { Container } from '@mui/system';
import styles from '../Assets/Css/ArticleStyles';
import Footer from '../Layouts/Footer';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { capitalize } from '../Services/capitalize';

function Deratisation() {

  let { slug } = useParams();
  
  if (slug)
    slug = capitalize(slug)

  const bannerTitle = slug ? `Dératisation des rats et souris à ${slug}` : 'Dératisation des rats et souris'
  const title = slug ? `Dératisation des Rats et Souris à ${slug} | JLM Multitraitement` : "Dératisation des Rats et Souris | JLM Multitraitement"

  useEffect(() => {
    document.title = title;
  }, [title])
  
 
  return (
    <>
        <Navbar />
        
        <Banner title={bannerTitle}/>
    
        <div style={styles.spacer}></div>

        <Container maxWidth="lg">
            <p style={styles.spacing}>Vous souhaitez vous débarrasser des nuisibles qui envahissent votre habitation ou votre local professionnel? les rats, les souris, les mulots (…) sont des animaux qui adorent se nicher sous votre toit et il est difficile de les faire partir selon ses propres moyens. Ne vous ruinez pas en méthodes trop peu efficaces, comme la mort aux rats, les pièges à souris qui peuvent se montrer couteux que le long terme et extrêmement dangereux pour vos animaux de compagnie (chiens, chats …) ou les membres de votre famille comme les enfants ou les personnes âgées. Pour en finir une bonne fois pour toutes avec les nuisibles, faites appel à des professionnels formés et compétents pour vous apporter une ambiance saine dans votre maison et ce, de façon définitive.</p>
            
            <h2 style={styles.spacing}>1) Une méthodologie précise pour éradiquer les nuisibles de façon définitive</h2>
            <p style={styles.spacing}>Notre entreprise de dératisation met à votre disposition des technicien hygiénistes pour intervenir dans votre maison ou votre entreprise. Sur une simple de votre part, il se rend chez vous pour examiner les lieux sur plusieurs points :</p>
            <ul style={styles.spacing}>
                <li>Connaître l’espèce qui envahit vos locaux (étude des greniers, des caves).</li>
                <li>Evaluer leur nombre ainsi que la rapidité de reproduction.</li>
                <li>Vous proposer une méthode adaptée pour soit les déloger, soit les exterminer avec les produits adéquats. Il est à noter que notre entreprise est certifiée Biocide, ce qui nous permet de vous proposer les meilleurs produits pour traiter vos locaux.</li>
                <li>Un devis sera établit et une fois signé, les travaux pourront commencer.</li>
            </ul>
            <p style={styles.spacing}>Nous disposons également d’appâts qui ne sont en aucun cas offensifs pour les chiens et les chats pour vous débarrasser de ces nuisibles. Si nécessaire, nos techniciens sont aptes à démonter des prises électriques pour pouvoir placer ces appâts ou de la pâte conçue pour les attirer dans les murs ou dans les plaintes.</p>
        
        
            <h2 style={styles.spacing}>2) A quel moment faire appel à nous?</h2>
            <p style={styles.spacing}>Dans l’idéal, dès que vous constatez la présence des nuisibles : vous pouvez les voir physiquement ou constater des traces d’extrêmement, des trous rongés ou toutes sortes d’indices. Si vous avez le moindre doute, nous sommes à l’écoute pour vous conseiller et constater s’il s’agit d’animal parasite ou non.</p>
            <p style={styles.spacing}>Notre entreprise est au service des particuliers et des professionnels pour des actions spontanées (action à la demande du client) ou par service d’abonnement : nous mettons en place des visites régulières pour vérifier la salubrité de votre maison.</p>
            <p style={styles.spacing}>L’effet de nos actions varie entre 48 et 72 heures seulement : une durée qui varie en fonction de la méthode et des biocides utilisés.</p>
            <p style={styles.spacing}>Nos prestations peuvent être curatives et préventives : nous sommes à votre écoute pour étudier vos locaux sur une simple demande d’intervention. nous disposons d’une expérience et d’un savoir faire unique qui vous garantit de l’efficacité, de la rapidité et une grande qualité de travail.</p>
            <p style={styles.spacing}>Une fois l’intervention terminée, nous restons à votre service pour des visites de contrôle ou pour tout conseil de prévention pour éviter le retour des nuisibles.</p>
            <p style={styles.spacing}>Nos équipes se tiennent à votre disposition pour convenir d’un rendez vous ou pour toute information complémentaire.</p>

            <div style={styles.spacer}></div>
        </Container>

        <Footer />
    </>
  )
}

export default Deratisation