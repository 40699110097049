import React from 'react'
import { MyBanner, MyWrapper, MyTitle, MySubTitle, MyText } from '../Assets/Css/BannerInterventionStyles'

function BannerIntervention() {
  return (
        <MyBanner container>
            <MyWrapper>
                <MyTitle variant="h4" color="white">
                    <strong>Secteur d’intervention</strong>
                </MyTitle>
                <MySubTitle variant="h5" color="white">
                    JLM intervient dans tout le secteur de Lille et sa métropole.
                </MySubTitle>
                <MyText variant="h6" color="white">
                    Voici les principales villes où nous nous déplaçons : Lille, Lomme, Lesquin, Wavrin, Halluin, Quesnoy sur Deûle, Villeneuve d’Ascq, Marcq-en-Baroeul, Lambersart, Mons-en-Baroeul, Roubaix, Tourcoing, Armentières, Pérenchies, Roncq…
                </MyText>
            </MyWrapper>
        </MyBanner>
  )
}

export default BannerIntervention